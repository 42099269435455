import * as React from 'react'
import { Link } from 'gatsby'
import newtab from 'utils/newtab'

export default function CTALink({ style, ...props }) {
	return (
		<p className="empty:hidden" style={style}>
			<CTA {...props} />
		</p>
	)
}

export function CTA({ label, url, className = 'action lg', children }) {
	if (!url || (!children && !label)) return null

	const external = /^\/files\//g.test(url) || url.startsWith('http')

	const Content = () => (
		<>
			{children || label}
			<span className="arrow">➞</span>
		</>
	)

	return external ? (
		<a className={className} href={url} {...newtab}>
			<Content />
		</a>
	) : (
		<Link className={className} to={url}>
			<Content />
		</Link>
	)
}
